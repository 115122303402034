/**
* Description of the module and the logic it provides
*
* @module cartridge/js/wishlist_status
*/

'use strict';
var util = require('./util'),
    page = require('./page'),
    bluecore = require('./bluecore'),
    isEvents = require('./interaction-studio-events')

var WishListStatus = {
    init: function () {
        var handler = WishListStatus.guestHandler;
        if (!window.User.anonymous) {
            handler = WishListStatus.customerHandler;
        }
        $(document).on('click', '.add-to-favorites.logged', handler);
    },

    guestHandler: function (e) {
        e.preventDefault();
        var elem = $(this).addClass('favorite');

        var isFavorited = elem.hasClass('added') ? 'remove' : 'add';
        var pid = $(this).data('pid');
        var price = $(this).data('price');
        var priceLocal = $(this).data('priceLocal');
        var styleColor = $(this).data('styleColor');
        // MDC-5463
        var qty = '1';
        if (isFavorited === 'add') {
            isEvents.addToFavorites(pid, price, priceLocal, styleColor, qty);
            $(document).trigger('customAddToFavorites', [ {pid: pid} ]);
        } else {
            isEvents.removeFromFavorites(pid, price, priceLocal, styleColor);
            $(document).trigger('customRemoveFromFavorites', [ {pid: pid} ]);
        }

        return elem;
    },

    customerHandler: function (e) {
        e.preventDefault();
        var elem = $(this),
            pid = elem.data('pid'),
            price = elem.data('price'),
            name = elem.data('name'),
            notification = elem.data('notification'),
            priceLocal = elem.data('priceLocal'),
            styleColor = elem.data('styleColor'),
            qty = '1',
            colorid = elem.data('colorid'),
            recipeId = elem.data('recipe-id') || '',
            url = window.Urls.wishlistAjax + '?pid=' + pid + '&colorid=' + colorid + '&recipeId=' + recipeId + '&format=ajax';

        if ($(this).parent().hasClass('sfl-item-image-wrapper') && !window.User.authenticated) {
            sessionStorage.sflWishlistItem = $(this).closest('.sfl-item').data('plid');
            return window.location.assign(util.appendParamToURL(Urls.loginShow, 'original', Urls.sflLoginReturn));
        }

        $.getJSON(url, function (data) {
            if (data && data.success) {
                $('.add-to-favorites[data-pid="' + pid + '"]').toggleClass('added', data.isAdded);
                if ($('.pt_wish-list').length && $('#QuickViewDialog').is(':visible')) {
                    $('div.ui-widget').find('button.ui-widget').addClass('wishlistUpdated');
                    $('.wishlistUpdated').on('click', function() {
                        window.location.reload();
                    });
                } else {
                    $.ajax({
                        type: 'GET',
                        url: window.Urls.getWishListItems,
                        success: function (result) {
                            sessionStorage.setItem('favItems', result.wishList);
                            if (result && result.wishList && result.wishList.length > 0) {
                                for (var k = 0; k <result.wishList.length; k++) {
                                    if ($('.add-to-favorites[data-pid='+result.wishList[k]+']').length) {
                                        $('.add-to-favorites[data-pid='+result.wishList[k]+']').addClass('added');
                                    }
                                }
                            }
                        }
                    });
                }
            }
        })

        var isFavorited = elem.hasClass('added') ? 'remove' : 'add';
        if (isFavorited === 'add') {
            if(notification){
                util.setNotification("You've added <span>"+name+"</span> to your favorites! <a href='javascript:void(0)' class='notification-undo'>Undo</a>", true);
            }
            isEvents.addToFavorites(pid, price, priceLocal, styleColor, qty);
            $(document).trigger('customAddToFavorites', [ {pid: pid} ]);
        } else {
            if(notification){
                util.setNotification("You've removed <span>"+name+"</span> from your favorites! <a href='javascript:void(0)' class='notification-undo'>Undo</a>", true);
            }
            isEvents.removeFromFavorites(pid, price, priceLocal, styleColor);
            $(document).trigger('customRemoveFromFavorites', [ {pid: pid} ]);
        }
    },

    save4laterLogin: function (e) {
        var form = $(e.currentTarget);

        var pid = form.data('pid');
        var container = $('.cart-row[data-uuid="' + form.data('uuid') + '"]');

        var data = {
            pid: pid,
            canBeFulfilled: true
        };

        var method = container.find('.item-delivery-options').find('input:checked').val();
        data.method = method;

        if (container.hasClass('full-out-of-stock')) {
            data.fulfillIssue = 'out of stock';
            data.canBeFulfilled = false;
        }
        $(document).trigger('save4later:add', data);
        page.refresh();
    },

    customerLoginHandler: function (e) {
        e.preventDefault();
        var elem = $(this),
            plp = $('.pt_product-search-result'),
            pdp = $('.pt_product-details'),
            cart = $('.pt_cart'),
            wishlist = $('.add-to-wishlist');

        if (cart.length) {
            elem = wishlist;
        } else if (pdp.length) {
            elem = $('.add-to-favorites');
        } else if (plp.length) {
            elem = $(e.currentTarget);
        }

        var wlTriggered = false;
        var login = $('.login-in'),
            pid = elem.data('pid'),
            colorid = elem.data('colorid'),
            recipeId = elem.data('recipe-id') || '',
            url = window.Urls.wishlistAjax + '?pid=' + pid + '&colorid=' + colorid + '&recipeId=' + recipeId + '&format=ajax';

        if (login.length) {
            $('.ui-widget-overlay').trigger('click');
            elem.removeClass('login-modal');
            if (cart.length) {
                var prod = wishlist.closest('tr').find('.cart-pid').val();
                bluecore.pushRemoveItem(prod, wishlist.data('masterproductid'));
                isEvents.saveForLater(prod);
            } else {
                wlTriggered = true;
                $.getJSON(url, function (data) {
                    if (data && data.success) {
                        var elem = elem + '[' + 'data-pid="' + pid + '"' + ']';
                        $(elem).toggleClass('added', data.isAdding);
                        if (data.isAdding) {
                            $(document).trigger('customAddToFavorites', [ {pid: pid} ]);
                        } else {
                            $(document).trigger('customRemoveFromFavorites', [ {pid: pid} ]);
                        }
                    }
                    page.refresh();
                });
            }
        }
        if ($(this).parent().hasClass('sfl-item-image-wrapper') && !window.User.authenticated) {
            sessionStorage.sflWishlistItem = $(this).closest('.sfl-item').data('plid');
            return window.location.assign(util.appendParamToURL(Urls.loginShow, 'original', Urls.sflLoginReturn));
        }

        if (!wlTriggered) {
            $.getJSON(url, function (data) {
                if (data && data.success) {
                    var elem = elem + '[' + 'data-pid="' + pid + '"' + ']';
                    $(elem).toggleClass('added', data.isAdding);
                    if (data.isAdding) {
                        $(document).trigger('customAddToFavorites', [ {pid: pid} ]);
                    } else {
                        $(document).trigger('customRemoveFromFavorites', [ {pid: pid} ]);
                    }
                }
            });
        }
    },

    getStatus: function () {
        var pid = $('#pid').val();
        if (pid) {
            $.ajax({
                url: window.Urls.wishlistStatus + '?pid=' + pid + '&format=ajax',
                dataType: 'html'
            }).done(function (data) {
                if (data) {
                    $('.wishlist-icon-wrap').replaceWith(data);
                }
            });
        }
    }
}

module.exports = WishListStatus;
