'use strict';

var fluidconfigure = require('fluidconfigure/fluidconfigure'),
    blucore = require('../bluecore'),
    optinmodal = require('../optinmodal'),
    tagmanager = require('googletags/tagmanager'),
    isStudio = require('../interaction-studio-events'),
    talkablemodal = require('../talkablemodal');

var loadOptInTimeInterval = 2000;

function loadOptInModal(orderNo) {
    if (!SitePreferences.SMS2_ENABLED && !SitePreferences.TALKABLE_POST_CHECKOUT_ENABLED) {
        return;
    }

    var displayOptIn = $('#displayOptIn').length ? $('#displayOptIn').val() : null;
    var serveHotJar = $('#serveHotJar').length ? $('#serveHotJar').val() : null;
    var dismissCheckoutOptIn = Cookies.get('dismissCheckoutOptIn');
    var dismissHotJarCookie = Cookies.get('dismissHotJarCookie');
    var optinType = '';
    if (serveHotJar == 'true' && SitePreferences.SMS2_CONFIRM_HOTJAR_URL && (typeof dismissHotJarCookie == 'undefined' || dismissHotJarCookie == 'false')) {
        optinType = 'hotjar';
    }
    if (displayOptIn == 'true' && (typeof dismissCheckoutOptIn == 'undefined' || dismissCheckoutOptIn == 'false')) {
        optinType = 'checkout';
    }
    if (optinType !== '' && SitePreferences.SMS2_CONFIRM_OPTIN_ENABLED) {
        optinmodal.loadOptIn(null, optinType);
    } else if (SitePreferences.TALKABLE_POST_CHECKOUT_ENABLED) {
        talkablemodal.loadTalkablePostCheckout(orderNo);
    }
}

function sendGA360 () {
    var ga360ConfirmationData = $('#ga360ConfirmationData').length ? $('#ga360ConfirmationData').val() : null;

    if (ga360ConfirmationData && ga360ConfirmationData.length) {
        try {
            ga360ConfirmationData = JSON.parse(ga360ConfirmationData);
        } catch (e) {
            ga360ConfirmationData = null;
        }

        if (ga360ConfirmationData && ga360ConfirmationData !== null) {
            var queryString = window.location.search;

            if (queryString != '') {
                var urlParams = new URLSearchParams(queryString);

                if (urlParams.get('srcCode') != null) {
                    ga360ConfirmationData.ecommerce.affiliation = 'Adobe';
                } else if (urlParams.get('utm_medium') != null) {
                    ga360ConfirmationData.ecommerce.affiliation = 'GTM';
                }
            }

            var emailAddress = $('#transactionalEmail').val();
            if (emailAddress && emailAddress.length) {
                // eslint-disable-next-line camelcase
                ga360ConfirmationData.email_address = emailAddress;
            }

            tagmanager.pushToDataLayer({ecommerce: null});
            tagmanager.pushToDataLayer(ga360ConfirmationData);
        }
    }
}

exports.init = function () {
    var orderNumber = $('.value.order-num').length > 0 ? $('.value.order-num').text() : '';
    if (SitePreferences.SMS2_CONFIRM_OPTIN_ENABLED || SitePreferences.TALKABLE_POST_CHECKOUT_ENABLED) {
        setTimeout(loadOptInModal(orderNumber), loadOptInTimeInterval);
    }
    var confirmationDetails = $('input[name=confirmationDetails]').val() || '';
    if (confirmationDetails !== undefined && confirmationDetails !== null && confirmationDetails !== '') {
        try {
            confirmationDetails = JSON.parse(confirmationDetails);
        } catch (error) {
            confirmationDetails = {
                page: {
                    orderNumber: orderNumber
                },
                products: []
            };
        }
        isStudio.sendOrderConfirmation(confirmationDetails);
    }

    $(function() {
        sendGA360();
    });

    fluidconfigure.initOrderDetailsLineItems();
    blucore.pushTransactionalEmail();
}
